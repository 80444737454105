import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PaymentSelect from '@shared/ui/misc/PaymentSelect';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import Table from '@shared/ui/display/Table';
import { PaymentMethodFormType, addPaymentMethod as addPaymentMethodAction, closeSidebar as closeSidebarAction, editPaymentMethod as editPaymentMethodAction, } from '@entities/current-user';
import { useAppSelector } from '@app/store/Hooks';
import Button from '@shared/ui/buttons/Button';
import PaymentSystem from '@entities/payment-system';
import Block from '../../components/BlockContainer';
import { PAYMENTS_MOCK_DATA, PAYMENT_INFO_MOCK, SUBSCRIPTION_PLANS_MOCK } from './constants';
import { getPaymentColumnsDefinition } from './utils';
import PaymentInfo from './components/PaymentInfo';
import SubscriptionPlanList from './components/SubscriptionPlanList';
import TooltipLabel from './components/TooltipLabel';
import PlatformBalance from './components/PlatformBalance';
import EmptySelect from './components/EmptySelect';
import AddNewPayment from '../../sidebars/AddNewPayment';
import EditPaymentMethod from '../../sidebars/EditPaymentMethod';
import SubscriptionRowHeader from './components/SubscriptionRowHeader';
const BlockContainer = styled.div(props => ({
    marginBottom: `${props.theme.spacing_sizes.m * 2}px`,
}));
const AddBtnContainer = styled.div(() => ({
    whiteSpace: 'nowrap',
    alignSelf: 'end',
}));
const SelectsContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.xs * 7,
    marginBottom: props.theme.spacing_sizes.m,
}));
const SelectContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: props.theme.spacing_sizes.xm,
}));
const OPTIONS_MOCK = [
    {
        label: '*******84875',
        value: '2',
        hasError: false,
        paymentSystem: PaymentSystem.PayPal,
        assetsSum: 124,
        currency: '$',
    },
    {
        label: '*******84875',
        value: '3',
        hasError: true,
        paymentSystem: PaymentSystem.MasterCard,
        assetsSum: 241,
        currency: '$',
    },
];
const BALANCE_DETAILS = {
    currency: '$',
    assetsSum: 125.01,
};
const Subscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { paymentMethodFormType, isPaymentFormOpen } = useAppSelector(state => state.user);
    const paymentColumnDefintion = useMemo(() => getPaymentColumnsDefinition(t), [t]);
    const openSidebar = (paymentType) => {
        if (paymentType === PaymentMethodFormType.AddPaymentMethod) {
            dispatch(addPaymentMethodAction());
        }
        else {
            dispatch(editPaymentMethodAction());
        }
    };
    const handleSidebarClose = useCallback(() => {
        dispatch(closeSidebarAction());
    }, [dispatch]);
    const handleMethodSelect = () => { };
    const handleMethodAdd = () => {
        openSidebar(PaymentMethodFormType.AddPaymentMethod);
    };
    const handleMethodEdit = () => {
        openSidebar(PaymentMethodFormType.EditPaymentMethod);
    };
    const displayedSidebarElement = useMemo(() => {
        switch (paymentMethodFormType) {
            case PaymentMethodFormType.AddPaymentMethod:
                return _jsx(AddNewPayment, { onClose: handleSidebarClose });
            case PaymentMethodFormType.EditPaymentMethod:
                return _jsx(EditPaymentMethod, { onClose: handleSidebarClose });
            default:
                return null;
        }
    }, [paymentMethodFormType, handleSidebarClose]);
    return (_jsxs(_Fragment, { children: [_jsx(BlockContainer, { children: _jsx(Block, { title: t('settings.tabs.subscription.subscription_plan.title'), children: _jsx(SubscriptionPlanList, { plansList: SUBSCRIPTION_PLANS_MOCK }) }) }), _jsx(BlockContainer, { children: _jsx(Block, { title: t('settings.tabs.subscription.payment_method.title'), children: _jsxs(SelectsContainer, { children: [_jsxs(SelectContainer, { children: [_jsx(PlatformBalance, { label: t('settings.tabs.subscription.payment_method.main'), tooltipText: t('settings.tabs.subscription.payment_method.main'), innerText: t('settings.tabs.subscription.payment_method.platform_balance'), amount: BALANCE_DETAILS.assetsSum, currency: BALANCE_DETAILS.currency }), _jsx(AddBtnContainer, { children: _jsx(Button, { variant: 'filled', color: 'primary', size: 'large', children: t('settings.tabs.subscription.payment_method.add_balance') }) })] }), _jsx(SelectContainer, { children: OPTIONS_MOCK.length ? (_jsx(PaymentSelect, { label: (_jsx(TooltipLabel, { labelText: t('settings.tabs.subscription.payment_method.secondary'), tooltipText: t('settings.tabs.subscription.payment_method.secondary') })), paymentErrorText: t('settings.tabs.subscription.payment_method.payment_method_error'), options: OPTIONS_MOCK, onChange: handleMethodSelect, onMethodAdd: handleMethodAdd, onMethodEdit: handleMethodEdit, value: '2' })) : (_jsx(EmptySelect, { onMethodAdd: handleMethodAdd })) })] }) }) }), _jsx(BlockContainer, { children: _jsxs(Block, { title: t('settings.tabs.subscription.payment_table.title'), gap: theme.spacing_sizes.m * 3, children: [_jsx(PaymentInfo, Object.assign({}, PAYMENT_INFO_MOCK)), _jsx(Table, { columnDef: paymentColumnDefintion, data: PAYMENTS_MOCK_DATA, components: {
                                HeaderRow: SubscriptionRowHeader,
                            }, className: '' })] }) }), _jsx(Sidebar, { isOpen: isPaymentFormOpen, children: displayedSidebarElement })] }));
};
export default Subscription;
